'use strict'


export default {
    computed: {
        
    },
    data () {
        return {
            clinic_model: {
                name: "",
                address: "",
                postcode: "",
                phonenumber: "",
                faxnumber: "",
                status: "",
            },
            rules: {
                name: [{
                required: true,
                trigger: 'blur'
                }],
            },
            clinic_schema: {
                fields: [
                    {
                        type: 'input',
                        inputType: 'text',
                        label: '施設名',
                        model: 'name',
                        placeholder: '施設名を入力してください',
                        featrued: true,
                        required: true
                    },
                    {
                        type: 'input',
                        inputType: 'text',
                        label: '郵便番号',
                        model: 'postcode',
                        placeholder: '郵便番号を入力してください',
                        featrued: true,
                        required: true
                    },
                    {
                        type: 'input',
                        inputType: 'text',
                        label: '住所',
                        model: 'address',
                        placeholder: '住所を入力してください',
                        featrued: true,
                        required: true
                    },
                    {
                        type: 'input',
                        inputType: 'text',
                        label: '電話番号',
                        model: 'phonenumber',
                        placeholder: '電話番号を入力してください',
                        featrued: true,
                        required: true
                    },
                    {
                        type: 'input',
                        inputType: 'text',
                        label: 'FAX番号',
                        model: 'faxnumber',
                        placeholder: 'FAX番号を入力してください',
                        featrued: true,
                        required: true
                    },
                ]
            },
            formOptions: {
              validateAfterLoad: true,
              validateAfterChanged: true,
              validateAsync: true
            }
        }
    }
};