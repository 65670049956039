<template>
  <v-app>
    <v-card width="98%" class="mx-auto mt-5 pa-5">
      <LocaleSwitcher />
      {{
        $t(
          "welcome.処方されたコンタクトレンズを、こちらのページからご注文いただけます。"
        )
      }}
      <v-card-title class="justify-center" v-if="clinic">
        {{ clinic.name }}
      </v-card-title>
      <v-img
        v-if="clinic"
        height="50"
        :src="clinic.logourl"
        aspect-ratio="1.7"
        contain
      ></v-img>
      <v-card-text>
        <div v-if="clinic">
          {{ $t("welcome.販売元") }}：<b>{{ clinic.saler_name }}</b>
          <br />
          {{ clinic.comment }}
          <br /><br />
          <ul style="list-style: none">
            <li>
              {{ $t("welcome.License_Number") }}:
              {{ clinic.sales_license }}
            </li>
            <li>
              <a :href="clinic.sct_url" target="_brank">{{
                $t("welcome.特定商取引法に基づく表記")
              }}</a>
            </li>
            <li>{{ $t("welcome.住所") }}: {{ clinic.saler_address }}</li>
            <li>
              {{ $t("welcome.ホームページ") }}:
              <a :href="clinic.saler_weburl" target="_brank">{{
                clinic.saler_weburl
              }}</a>
            </li>

            <li>
              {{ $t("welcome.ご注文品の配送状況や破損品交換のお問い合わせ") }}
            </li>
            <li>{{ $t("welcome.営業日") }}: {{ clinic.saler_date }}</li>
            <li>{{ $t("welcome.休業日") }}: {{ clinic.saler_holiday }}</li>
            <li>{{ $t("welcome.営業時間") }}: {{ clinic.saler_time }}</li>
            <li>{{ $t("welcome.電話番号") }}: {{ clinic.saler_call }}</li>
            <li>{{ $t("welcome.E-mail") }}: {{ clinic.saler_email }}</li>
          </ul>
        </div>
      </v-card-text>
      <v-card-text>
        <p>
          {{ $t("welcome.welcome_lead") }}<br /><br />
          {{ $t("welcome.welcome_sgin_regist") }}。
        </p>

        <div>
          <v-btn
            outlined
            color="indigo"
            elevation="2"
            large
            v-on:click="regist()"
            class="ml-10"
          >
            {{ $t("sginIn.新規登録") }}
          </v-btn>

          <v-btn
            outlined
            color="green"
            elevation="2"
            large
            v-on:click="login()"
            class="ml-5"
          >
            {{ $t("sginIn.ログイン") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import { Firebase } from "@/config/firebase";
import clinic from "@/mixins/clinic";
import LocaleSwitcher from "@/components/LocaleSwitcher";
export default {
  name: "Welcome",
  data() {
    return {
      clinic: null,
    };
  },
  mixins: [clinic],
  components: { LocaleSwitcher },
  created() {
    this.clinicFetchById();
  },
  computed: {
    id() {
      return this.queryToStringL(this.$route.params.id);
    },
  },
  methods: {
    queryToStringL: function (value) {
      if (typeof value === "string" && value) {
        return value;
      }
      throw new Error("Invalid query value");
    },
    regist: function () {
      this.$router.push({ name: "signup", params: { id: this.id } });
    },
    login: function () {
      this.$router.push({ name: "login", params: { id: this.id } });
    },
    clinicFetchById: async function () {
      try {
        const clinicRef = await Firebase.firestore()
          .collection("Clinics")
          .doc(this.id);
        await clinicRef.get().then((doc) => {
          if (doc.exists) {
            this.clinic = doc.data();
            this.clinic.id = doc.id;
          } else {
            console.log("No clinic document!");
          }
          this.error = true;
        });
      } catch (e) {
        this.error = true;
        this.$router.push({
          name: "error",
          params: { message: "表示できません。" },
        });
      } finally {
        this.loading = false;
      }
      this.clinic_model.id = this.id;
      this.clinic_model.name = this.clinic.name;
      this.clinic_model.address = this.clinic.address;
      this.clinic_model.postcode = this.clinic.postcode;
      this.clinic_model.phonenumber = this.clinic.phonenumber;
      this.clinic_model.faxnumber = this.clinic.faxnumber;
      this.clinic_model.status = this.clinic.status;
      this.clinic_model.makers = this.clinic.makers;
      this.clinic_model.logourl = this.clinic.logourl;
      this.clinic_model.weburl = this.clinic.weburl;
    },
  },
};
</script>
